<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import InputField from '@/components/InputField'

export default {
  name: 'ChangeProfilePriceAdd',

  components: {
    Action,
    Icon,
    InputField,
  },

  data () {
    return {
      formData: {
        newPriceAdd: null,
      },
      loading: false,
    }
  },

  validations: {
    formData: {
      newPriceAdd: {
        required,
      },
    },
  },
  filters: {
    currency (value) {
      let val = value.toFixed(2)
      return val.toString().replace('.', ',') + '%'
    },
  },
  computed: {
    ...mapGetters('products', ['getPriceAdd']),
  },

  mounted () {
    if (!this.getPriceAdd) {
      this.checkPriceAdd()
    }
  },

  methods: {
    ...mapActions(['setFeedback']),
    ...mapActions('products', ['checkPriceAdd', 'updatePriceAdd']),

    clearForm () {
      this.formData = {
        newPriceAdd: null,
      }
    },

    handleSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      this.loading = true
      this.updatePriceAdd(this.formData).then((retorno) => {
        this.loading = false
        if (retorno.message === 'Valor de acrescimo invalido') {
          this.setFeedback({ title: 'Valor de acréscimo inválido', message: '', type: 'error' })
        } else if (retorno.message === 'OK') {
          this.setFeedback({ title: 'Acréscimo alterado com sucesso!', message: 'Preço de acréscimo alterado para ' + this.formData.newPriceAdd + '%', type: 'success' })
          this.checkPriceAdd()
            .then(() => {
              this.clearForm()
              this.$v.$reset()
            })
        } else {
          this.setFeedback({ title: 'Erro de conexão', message: 'Tente novamente mais tarde', type: 'success' })
        }
      }).catch(() => {
        this.loading = false
        this.clearForm()
      })
    },
  },
}
</script>

<template>
  <div :class="$classes['home']">
    <div :class="$classes['box']">
      <h1 :class="$classes['title']">Preço com acréscimo</h1>
      <form @submit.prevent="handleSubmit">
        <div :class="$classes['title-label']">
          <span>Acréscimo atual: </span>
          <span v-if="this.getPriceAdd" :class="$classes['is-enabled']">{{ this.getPriceAdd | currency }}</span>
          <span v-else :class="$classes['is-disabled']">Não possui</span>
        </div>
        <input-field v-model="formData.newPriceAdd" label="Novo preço de acréscimo" placeholder="Escreva aqui"
          :validation="$v.formData.newPriceAdd" :tabindex="1" />
        <div :class="$classes['form-actions']">
          <action full-width variant="contained" color="primary" type="submit" :loading="loading" :disabled="loading"
            :tabindex="2">
            Salvar
          </action>
        </div>
      </form>
      <action variant="flat" color="secondary" uppercase :to="{ name: 'home' }">
        <icon name="long-arrow-alt-left" btnIcon />
        Voltar
      </action>
    </div>
  </div>
</template>

<style module>
.home {
  height: 100%;
  background: url('~@/assets/images/home.png') no-repeat center;
  background-size: cover;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  width: 380px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 30px 35px;
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 100%;
  height: 110px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 40px;
  color: rgba(41, 41, 41, 0.8);
}

.title-label {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: rgba(41, 41, 41, 0.8);
  margin-bottom: 30px;
}

.title-label .is-disabled {
  color: #D14040;
}

.title-label .is-enabled {
  color: #4a94d4;
}

.form-actions {
  margin-top: 30px;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .home {
    padding: 20px;
  }

  .box {
    width: 100%;
    max-width: 380px;
  }
}
</style>
